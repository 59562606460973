*:focus-visible {
  outline: 0 !important;
}

@media (max-width: 768px) {
  .wrap_c028 {
    bottom: 8em !important;
    z-index: 0 !important;
  }
}
.modal-content {
  border-radius: 0;
}

.snakbar {
  font-size: 1.2em;
}

.defult-style {
  background-color: #fff;
  color: #000;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.27);
  -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.27);
}
.defult-style .navbar-nav .nav-link {
  color: #000 !important;
}
.defult-style .navbar-nav li a {
  color: #000 !important;
}

.custom-style {
  background: transparent;
  box-shadow: none;
}
.custom-style .navbar-nav .nav-link {
  color: #fff;
}
.custom-style .navbar-nav li a {
  color: #fff;
}

.fill-white {
  fill: #fff;
  width: 20px;
  height: 20px;
}

.fill-black {
  fill: #000;
  width: 20px;
  height: 20px;
}

.cp {
  cursor: pointer !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.form-group {
  position: relative;
}
.form-group .info-icon {
  position: absolute;
  right: 0;
  top: 42.5%;
}

.main-wrapper {
  margin: 6.2em 0;
}
.main-wrapper .main {
  min-height: 200px;
}
@media (max-width: 768px) {
  .main-wrapper {
    margin: 0;
  }
}