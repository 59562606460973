/**

jQuery Awselect
Developed by: Prev Wong
Documentation: https://prevwong.github.io/awesome-select/
Github: https://github.com/prevwong/awesome-select/

**/

.immersive_awselect {
    overflow: hidden !important;
}

.awselect_bg {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(0, 0, 0, 0.8);
    -webkit-transition: 0.2s ease-in;
    -moz-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
}

.awselect_bg.animate {
    opacity: 1;
}

.awselect {
    float: left;
    width: 87%;
    position: relative;
    cursor: pointer;
    -webkit-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    margin-right: 10px;
}

.awselect>.bg {
    background: #fff;
    height: 0%;
    float: left;
    width: 100%;
    position: absolute;
    z-index: 1;
    -webkit-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.awselect .front_face {
    position: relative;
    padding: 20px 40px;
    color: #000;
    float: left;
    width: 100%;
}

.awselect .front_face>.bg {
    position: absolute;
    z-index: 0;
    float: left;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000 !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #000 !important;
    border-radius: 0 !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.awselect .front_face .content {
    float: left;
    width: 100%;
    position: relative;
    z-index: 2;
}

.awselect .front_face span {
    -webkit-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    float: left;
    width: 100%;
    color: inherit;
}

.awselect .front_face .current_value {
    position: absolute;
    left: 0;
}

.awselect .front_face .placeholder {
    position: relative;
}

.awselect .front_face .icon {
    float: right;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: rotate(180deg) translateY(-50%);
    transform-origin: center 0;
    -webkit-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.awselect .front_face .icon svg {
    width: 10px;
    height: 10px;
}

.awselect .back_face {
    z-index: 2;
    position: absolute;
    padding: 0px;
    -webkit-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateY(20px);
    opacity: 0;
    float: left;
    width: 100%;
    max-height: 200px;
    overflow: hidden;
    display: none;
}

.awselect .back_face.overflow {
    overflow-y: scroll;
}

.awselect .back_face ul {
    margin: 0;
    float: left;
    padding: 0;
    width: 100%;
}

.awselect .back_face ul li {
    float: left;
    width: 100%;
    display: block;
}

.awselect .back_face ul li a {
    color: inherit;
    opacity: 0.8;
    float: left;
    width: 100%;
    -webkit-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.awselect .back_face ul li a:hover {
    background: #000;
    opacity: 1;
    color: #fff;
}

.awselect.animate {
    z-index: 9999;
}

.awselect.animate .front_face .icon {
    transform: rotate(0deg) translateY(-50%);
}

.awselect.animate>.bg {
    height: 100%;
}

.awselect.animate2>.bg {
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.11);
    border: solid 1px;
    ;
}

.awselect.animate2 .back_face {
    transform: translateY(0px);
    opacity: 1;
}

.awselect.hasValue .front_face .placeholder {
    transform: translateY(20px);
    opacity: 0;
}

.awselect.placeholder_animate .front_face .current_value {
    opacity: 0;
}

.awselect.placeholder_animate2 .front_face .placeholder {
    transform: translateY(0);
    opacity: 1;
}

.awselect.transition_paused {
    transition: none !important;
}

.aw_cursor_pointer {
    float: left;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #ddd;
    overflow: hidden;
    padding: 5px;
    -webkit-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transform: scale(0.5);
}

.aw_cursor_pointer .inner {
    float: left;
    width: 100%;
    height: 0%;
    background: #fff;
    border-radius: 100%;
    -webkit-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
    transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.aw_cursor_pointer.animate {
    transform: scale(1);
}

.aw_cursor_pointer.animate .inner {
    height: 100%;
}
.awselect .front_face > .bg{
        border: none !important;
    }

/*# sourceMappingURL=awselect.css.map */