.lb-data .lb-zoomOut {
  background-color: red !important;
}

.lb-data .lb-zoomIn {
  background-color: red !important;
}

.lightbox {
  top: 5px !important;
  text-align: center !important;
  position: fixed;
}

.lb-loader {
  text-align: center !important;
}

.lightbox .lb-image {
  border: initial;
  height: calc(100vh - 10px) !important;
  object-fit: cover;
}

.lb-outerContainer {
  background: initial;
}

.lb-dataContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  min-width: 100%;
}

.lightboxOverlay {
  min-width: 100%;
  margin-top: -200px;
  min-height: 100%;
}

.lb-data,
.lb-data .lb-number {
  color: #000 !important;
  font-weight: bold;
  font-size: 30px;
}

.lb-data .lb-close,
.lb-nav a.lb-next,
.lb-nav a.lb-prev {
  background: initial;
  font-family: "simple-line-icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-size: 28px;
  color: #000 !important;
  opacity: 1;
  z-index: 11;
  margin: 0.2em 0.6em;
}

.lb-details {
  margin: 0.2em 0.6em;
}

.lb-data .lb-close:before {
  content: "\e082";
}

.lb-loader .lb-cancel {
  background: initial;
  display: inline-block;
  vertical-align: text-bottom;
  border: 0.25em solid #236591;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

.lb-nav {
  z-index: 0;
}

.lb-nav a.lb-next:before {
  content: "\e079";
  right: 10px;
  position: fixed;
  top: 50%;
  margin-top: -28px;
  font-weight: bold;
}

.lb-nav a.lb-prev:before {
  content: "\e07a";
  left: 10px;
  position: fixed;
  top: 50%;
  margin-top: -28px;
  font-weight: bold;
}

.lightboxOverlay {
  background-color: #fff !important;
}

@keyframes fadeInOverlay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.lb-container {
  height: 100vh;
}